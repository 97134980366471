import React from 'react'
import ExploreProfile from './ExploreProfile'

export default function Profilemain() {
  return (
    <div>
    <ExploreProfile/>  
    </div>
  )
}
