import React from 'react'
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";

export default function PourProductSlider() {
  const carouselData = [
    {
      name: "product name",
      image:
        "https://www.nike.sa/dw/image/v2/BDVB_PRD/on/demandware.static/-/Sites-akeneo-master-catalog/default/dw3febf6df/nk/e20/f/7/d/6/b/e20f7d6b_77f8_4a99_99e2_b2a2cb69de46.png?sw=520&sh=520&sm=fit",
    },
    {
      name: "product name",
      image:
        "https://www.nike.sa/dw/image/v2/BDVB_PRD/on/demandware.static/-/Sites-akeneo-master-catalog/default/dwea021436/nk/dad/7/3/e/3/e/dad73e3e_0759_4c4d_8d75_7a50d87e5459.png?sw=520&sh=520&sm=fit",
    },
    {
      name: "product name",
      image:
        "https://www.nike.sa/dw/image/v2/BDVB_PRD/on/demandware.static/-/Sites-akeneo-master-catalog/default/dw80239d9e/nk/2b2/9/2/4/7/3/2b292473_22d9_44ba_a567_5158185bdb18.png?sw=520&sh=520&sm=fit",
    },
    {
      name: "product name",
      image:
        "https://www.nike.sa/dw/image/v2/BDVB_PRD/on/demandware.static/-/Sites-akeneo-master-catalog/default/dw7ca3c425/nk/60d/2/e/8/7/c/60d2e87c_9eaa_46a0_b9aa_0f730291262b.png?sw=520&sh=520&sm=fit",
    },
    {
      name: "product name",
      image:
        "https://www.nike.sa/dw/image/v2/BDVB_PRD/on/demandware.static/-/Sites-akeneo-master-catalog/default/dw345ea6c3/nk/057/c/2/b/b/d/057c2bbd_d065_44eb_913f_51dd4f98d680.png?sw=520&sh=520&sm=fit",
    },
    {
      name: "product name",
      image:
        "https://www.nike.sa/dw/image/v2/BDVB_PRD/on/demandware.static/-/Sites-akeneo-master-catalog/default/dw3febf6df/nk/e20/f/7/d/6/b/e20f7d6b_77f8_4a99_99e2_b2a2cb69de46.png?sw=520&sh=520&sm=fit",
    },
    {
      name: "product name",
      image:
      "https://www.nike.sa/dw/image/v2/BDVB_PRD/on/demandware.static/-/Sites-akeneo-master-catalog/default/dw7ca3c425/nk/60d/2/e/8/7/c/60d2e87c_9eaa_46a0_b9aa_0f730291262b.png?sw=520&sh=520&sm=fit",
    },
    {
      name: "product name",
      image:
      "https://www.nike.sa/dw/image/v2/BDVB_PRD/on/demandware.static/-/Sites-akeneo-master-catalog/default/dw7ca3c425/nk/60d/2/e/8/7/c/60d2e87c_9eaa_46a0_b9aa_0f730291262b.png?sw=520&sh=520&sm=fit",
    },
    {
      name: "product name",
      image:
      "https://www.nike.sa/dw/image/v2/BDVB_PRD/on/demandware.static/-/Sites-akeneo-master-catalog/default/dw7ca3c425/nk/60d/2/e/8/7/c/60d2e87c_9eaa_46a0_b9aa_0f730291262b.png?sw=520&sh=520&sm=fit",
    },
    {
      name: "product name",
      image:
      "https://www.nike.sa/dw/image/v2/BDVB_PRD/on/demandware.static/-/Sites-akeneo-master-catalog/default/dw7ca3c425/nk/60d/2/e/8/7/c/60d2e87c_9eaa_46a0_b9aa_0f730291262b.png?sw=520&sh=520&sm=fit",
    },
  ];
  const scrollLeft = () => {
    document.getElementById("content").scrollLeft -= 400;
  };
  const scrollRight = () => {
    document.getElementById("content").scrollLeft += 400;
  };
  return (
    <>
<div className="flex flex-row lg:gap-[74px]">


  


          <div className="a relative lg:w-[112vh] ">
        <h1 className="relative lg:top-[1rem] text-start text-[#263238] lg:text-[19px]">
          Products For You
        </h1>

        <div className="absolute right-0 lg:top-[3.2rem] z-10 xs:top-[20px]">
          <button
            onClick={scrollLeft}
            className="relative lg:top-[77px] bg-[#0000002e] lg:h-[3rem] p-2 m-2 rounded-md lg:right-[67.5rem] carousel-bg"
          >
            <FiChevronLeft className="relative lg:right-[1px] text-black" />
          </button>
          <button
            onClick={scrollRight}
            className=" relative lg:top-[77px] bg-[#0000002e] lg:h-[3rem] p-2 m-2 rounded-md lg:left-[65px]"
          >
            <FiChevronRight className="relative text-black" />
          </button>
        </div>
        <div
          id="content"
          className="carousel p-4 flex items-center justify-start overflow-x-auto scroll-smooth scrollbar-hide lg:h-[auto] -m-4 mt-1"
        >
          {carouselData.map((item) => (
            <div className="carousel-card lg:ml-[-18px]" key={item.name}>
            
                <div className="p-4 lg:w-[219pxpx] xs:w-[14rem] drop-shadow-xl">
                  <div className="lg:h-[182px] lg:w-[183px]   shadow-inner pb-24 rounded-lg overflow-hidden text-center relative intrest-card bg-[#ffff] h-[137px]  ">
                    <img
                      src={item.image}
                      className="lg:h-[142px] l:w-[242px]"
                      alt=""
                    />
                    <h5 className="text-black font-all font-bold lg:mt-[9px]">
                      {item.name}
                    </h5>
                   
                  </div>
                </div>
       
            </div>
          ))}
        </div>
      </div>

<div className="b border-[1px] border-[#D2D2D2] lg:w-[15rem] rounded-md lg:mt-[31px]">

 <h2 className='mt-3 text-[#263238] font-semibold ml-3 text-[17px]' >Awards & Certificates</h2>
{/*  */}
<h4 className=' text-[#263238] mt-[18px] ml-3 font-semibold'  >Certificate of Recognization</h4>
<p className='italic text-[#263238] ml-3' style={{fontFamily:"fangsong"}} >february 2020 granted by the xyz sector of any town  Community</p>

<h4 className=' text-[#263238] mt-[18px] ml-3 font-semibold'  >Awards of Excellence</h4>
<p className=' text-[#263238] ml-3 italic' style={{fontFamily:"fangsong"}} >june 2009 guanted by xyz to product user</p>

</div>

</div>




    </>
  )
}
