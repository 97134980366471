import React from 'react'
import Postuserprofie from './Postuserprofie'

export default function PostProfileMain() {
  return (

    <>

     <Postuserprofie/>
      
    </>
  )
}
