import React, { useState } from "react";

import Logo from "../../assets/logo/Logo-Trans.png";
import image from "../../assets/logo/auth.png";
import { NavLink } from "react-router-dom";
export default function AuthMain() {
  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  return (
    <>
      <div className="lg:overflow-hidden">
        <div className="flex flex-row justify-start  ">
          <div className="item-1 h-screen lg:w-[100vh] bg-[#cb22290f] ">
            <div className="flex flex-col justify-center items-center mt-12 gap-[12pxs]">
              <img
                src={Logo}
                alt=""
                className="lg:w-[134pxl.] bounce-in-top  "
              />
            </div>
            <div className="text">
              <h1 className="text-center font-bold text-[35px] leading-[70px] text-[#738482] mt-9">
                Welcome to INO
              </h1>
              <p className="text-center  text-[#738482] font-all font-semibold ">
                Register now to gain access to your account{" "}
              </p>
            </div>
            <div className="image relative top-[14px]">
              <img
                src={image}
                className="mx-ato m-auto h-[21rem] image-coin"
                alt=""
              />
            </div>

            <div className="flex flex-row items-center gap-4 justify-center mt-[7rem]">
              <p className="text-[14px]">License Policy</p>
              <p className="border-x p-[8px]  text-[14px] border-black">
                Privacy Policy
              </p>
              <p className="text-[14px]">Terms and Conition</p>
            </div>
            <p className="text-[#738482] text-[13px] text-center">
              Copyrights © 2023 All Rights Reserved by{" "}
              <span className="text-[#CB2229]">INO</span>{" "}
            </p>
          </div>
          <div className="item-2  lg:w-[62vw]">
            <h1 className="text-center font-bold text-[29px] leading-[70px] text-black mt-[1rem]">
              Terms and Serivces
            </h1>
            <div className="text-description lg:w-[97%] mx-autp ">
              <p className="text-[14px] text-center relative left-[12px]">
                Welcome to Privacy Section of our website,By using our
                Website,You abide to agree on our terms and condition which are
                as follow
              </p>
              <div className="content-description-2 ">
                <div className="a mt-[14px]">
                  <span className=" ml-3"> 1. Acceptance of Terms: </span>
                  <br />
                  <span className="font-all text-[14px] relative left-[11px]">
                    By accessing or using our website, you explicitly
                    acknowledge and agree to these terms and conditions. If you
                    disagree with any of the terms mentioned here, you should
                    refrain from using our website.
                  </span>
                </div>
              </div>
              <div className="b mt-3">
                <span className=" ml-3">2. Limitations of Liability: </span>
                <br />
                <span className="font-all text-[14px] relative left-[11px]">
                  We do not accept any liability for the content, accuracy, or
                  quality of the information provided on our website. We <br />{" "}
                  reserve the right to remove, modify, or make changes to the
                  content of our website at any time, and without notice.
                </span>
                <div className="c mt-3">
                  <span className=" ml-3">
                    3. Intellectual Property Rights:
                  </span>
                  <br />
                  <span className="font-all text-[14px] relative left-[11px]">
                    The content of our website is protected by copyright laws
                    and other proprietary rights. You may not copy, reproduce,{" "}
                    <br /> or distribute any of our content without our explicit
                    permission.
                  </span>
                </div>
                {/*  */}

                <div className="d mt-3">
                  <span className=" ml-3">4. Third-Party Websites:</span>
                  <br />
                  <span className="font-all text-[14px] relative left-[11px]">
                    Our website may contain links to third-party websites that
                    are not owned or operated by us. We do not have control{" "}
                    <br /> over these websites, and therefore, we are not
                    responsible for the content or accuracy of these websites.
                  </span>
                </div>
                <div className="e mt-3">
                  <span className=" ml-3">5. Indemnification:</span>
                  <br />
                  <span className="font-all text-[14px] relative left-[11px]">
                    You agree to indemnify us and hold us harmless from any
                    claims, costs, or damages incurred due to your use of our
                    website.
                  </span>
                </div>

                <div className="f mt-3">
                  <span className=" ml-3">6. Governing Law:</span>
                  <br />
                  <span className="font-all text-[14px] relative left-[11px]">
                    These terms and conditions shall be governed by and
                    construed in accordance with the laws of the country of our
                    company's registration.
                  </span>
                </div>
                <div className="g mt-3  font-all relative left-[27px]">
                  We reserve the right to modify or update these terms and
                  conditions without prior notice. By <br /> continuing to use
                  our website after any updates, you acknowledge and agree to
                  these changes.
                </div>
              </div>
            </div>

            <div className="flex flex-col justify-center items-center gap-3 mt-4">
              <button className="border-[#CB2229] border-[1.1px] text-black p-[6px] rounded-full w-[18rem]">
                <label className="flex items-center justify-center">
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />

                  <span className="ml-2">I have read T&amp;C</span>
                </label>
              </button>
              <NavLink to="/auth/verification">
                <button class="bg-[#CB2229] text-white rounded-full font-all p-[6px]  w-[18rem]   transform hover:scale-105 duration-500 ease-in-out ">
                  Proceed
                </button>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
