import React from 'react'
import AddProfile from './AddProfile'

export default function AddProfilemain() {
  return (
    <div>
      <AddProfile/>
    </div>
  )
}
