import React from "react";
import Homeslider from "./HomePageMainComponent/Homeslider";
import Services from "./HomePageMainComponent/Services";
import MapStock from "./HomePageMainComponent/MapAndTrend/MapStock";
import Buttonsmain from "./HomePageMainComponent/TargerserviceButton/Buttonsmain";
import ProductHomeslider from "./HomePageMainComponent/ProductCardSlider/ProductHomeslider";
import Servivevideo from "./HomePageMainComponent/ServicesNear/Servivevideo";

function Homestock() {
  return (
    <div>
      {/* Main content */}
      <div className="p-4 sm:ml-[-2rem]">
        {/* Main content components */}
        <div class="p-4 sm:ml-64 bg-layout ">
          <div class="">
            <div class="flex items-center justify-center h-48 mb-8 slide-in-elliptic-top-fwd">
              <Homeslider />
            </div>

            <div class="grid grid-cols-2  mb-4 lg:mt-[18rem] lg:items-start lg:gap-[1rem] lg:relative lg:left-[30px]">
              <div class="flex items-center rounded   h-28 dark:bg-gray-800 lg:h-[33rem] relative lg:left-[4px]">
                <Services />
              </div>
              <div class="flex items-center justify-center rounded h-28 lg:mt-[208px]">
                <MapStock />
              </div>
            </div>
            <div className="services-section-button lg:mt-[4rem]">
              <Buttonsmain />
            </div>

            <div
              class="flex items-center justify-center h-48 mt-10 rounded  lg:h-[24rem]"
              style={{ border: "1px solid #D2D2D2" }}
            >
              <div className="w-[140vh]">
                <ProductHomeslider />
              </div>
            </div>

            <div
              className="flex items-center justify-center mt-10 rounded "
              style={{ border: "1px solid #D2D2D2" }}
            >
              <Servivevideo />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Homestock;
