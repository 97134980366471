import React from 'react'
import { useNavigate } from "react-router-dom";
export default function AllRequested() {
    const navigate = useNavigate();
  const handlelistener = () => {
    navigate(`/findproducts`);
  };
  return (
    <>
         <div className="bg-layout drop-shadow-md lg:w-[152vh] mb-12 lg:ml-[17rem] relative top-[6rem] swing-in-top-fwd">

<h1 className="font-all font-semibold text-center text-[24px] mt-4 mb-12">
Bussiness Card Requested
</h1>
<div className="flex flex-row items-center justify-center lg:gap-[25rem] w-[50%] mx-auto border-y-[1px] border-gray-500 lg:mb-4">
  <h1 className="font-all font-semibold text-[22px] text-[#000000]">
Select-All
  </h1>
<span className="--select-action--all">
<input type="checkbox" name="" id="" />

</span>

</div>
<div className="--content--inquiries">

<div className=" lg:w-[85%] lg:mx-auto flex flex-col lg:gap-[4px]">
  {/* --1  */}
<div className="flex flex-row gap-[104px] lg: items-center border-y border-gray-200 py-[6px]">
  <div className="--img">

    <input type="checkbox" name="" id="" />

  </div>
  <div className="-- flex  flex-col gap-[4px]">
   
    <span className='inline font-all'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga, molestias.</span>
  </div>
  <div className="flex flex-row items-center lg:gap-[32px]">

<button class="bg-[#F8F8F8] shadow-lg p-[3px] w-[12rem] rounded-full transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white flex flex-row items-center justify-center gap-2">View   </button>
</div>
</div>
{/* --2  */}
<div className="flex flex-row gap-[104px] lg: items-center border-y border-gray-200  py-[6px]">
  <div className="--img">

<input type="checkbox" name="" id="" />

  </div>
  <div className="-- flex  flex-col gap-[12px]">
   
    <span className='inline font-all'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga, molestias.</span>
  </div>
  <div className="flex flex-row items-center lg:gap-[32px]">

<button class="bg-[#F8F8F8] shadow-lg p-[3px] w-[12rem] rounded-full transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white flex flex-row items-center justify-center gap-2">View   </button>
</div>
</div>
{/* --3  */}
<div className="flex flex-row gap-[104px] lg: items-center border-y border-gray-200  py-[6px]">
  <div className="--img">
<input type="checkbox" name="" id="" />

  </div>
  <div className="-- flex  flex-col gap-[4px]">
   
    <span className='inline font-all'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga, molestias.</span>
  </div>
  <div className="flex flex-row items-center lg:gap-[32px]">

<button class="bg-[#F8F8F8] shadow-lg p-[3px] w-[12rem] rounded-full transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white flex flex-row items-center justify-center gap-2">View   </button>
</div>
</div>
  {/* --1  */}
  <div className="flex flex-row gap-[104px] lg: items-center border-y border-gray-200 py-[6px]">
  <div className="--img">
<input type="checkbox" name="" id="" />

  </div>
  <div className="-- flex  flex-col gap-[4px]">
   
    <span className='inline font-all'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga, molestias.</span>
  </div>
  <div className="flex flex-row items-center lg:gap-[32px]">

<button class="bg-[#F8F8F8] shadow-lg p-[3px] w-[12rem] rounded-full transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white flex flex-row items-center justify-center gap-2">View   </button>
</div>
</div>
{/* --2  */}
<div className="flex flex-row gap-[104px] lg: items-center border-y border-gray-200  py-[6px]">
  <div className="--img">
<input type="checkbox" name="" id="" />

  </div>
  <div className="-- flex  flex-col gap-[4px]">
   
    <span className='inline font-all'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga, molestias.</span>
  </div>
  <div className="flex flex-row items-center lg:gap-[32px]">

<button class="bg-[#F8F8F8] shadow-lg p-[3px] w-[12rem] rounded-full transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white flex flex-row items-center justify-center gap-2">View   </button>
</div>
</div>
{/* --3  */}
<div className="flex flex-row gap-[104px] lg: items-center border-y border-gray-200  py-[6px]">
  <div className="--img">
<input type="checkbox" name="" id="" />

  </div>
  <div className="-- flex  flex-col gap-[4px]">
   
    <span className='inline font-all'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga, molestias.</span>
  </div>
  <div className="flex flex-row items-center lg:gap-[32px]">

<button class="bg-[#F8F8F8] shadow-lg p-[3px] w-[12rem] rounded-full transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white flex flex-row items-center justify-center gap-2">View   </button>
</div>
</div>

</div>


<div className="button--action--reverse flex justify-center ">



<button onClick={handlelistener} class="my-[2rem] bg-[#F8F8F8] shadow-lg p-[3px] w-[12rem] rounded-full transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  border-[1px] border-[#CB2229] hover:text-white flex flex-row items-center justify-center gap-2">Go Back  </button>
</div>

</div>


 </div>
    </>
  )
}
