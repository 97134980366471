import React from "react";
// css
import "./assets/css/Animation.css";
// router dom
import {
  createBrowserRouter,
  Route,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
// Layout
import Mainlayout from "./Layout/Mainlayout";
// pages
import Homestock from "./pages/home/Homestock";
import Chat from "./pages/Chats/Chat";
import Termstock from "./pages/Terms&Service/Termstock";
import Privacypolicymain from "./pages/Privacypolicy/Privacypolicymain";
import Licensestock from "./pages/License/Licensestock";
import Helpmain from "./pages/Helpcenter/Helpmain";
import Referalstock from "./pages/Referal/Referalstock";
import Contactstock from "./pages/Contact/Contactstock";
import Walletstock from "./pages/Wallet/Walletstock";
import Addmoney from "./pages/Wallet/Transferfund/Addmoney";
import TransferMain from "./pages/Wallet/Rewards/TransferMain";
import WalletsMoney from "./pages/Wallet/Transferfund/WalletsMoney";
import WithdarwWallet from "./pages/Wallet/Withdrawfunds/WithdarwWallet";
import Withdrawtransfersuccess from "./pages/Wallet/Withdrawfunds/Withdrawtransfersuccess";
import ProductMainComponent from "./pages/home/ServicesByINO/FindProduct/ProductMainComponent";
import ExploremainComponent from "./pages/home/ServicesByINO/ExploreService/ExploremainComponent";
import JobMain from "./pages/home/ServicesByINO/FinDjobs/JobMain";
import AddProjectMain from "./pages/home/ServicesByINO/AddProject/AddProjectMain";
import ListMain from "./pages/home/ServicesByINO/ListService/ListMain";
import AuthLayout from "./Layout/AuthLayout";
import AuthMain from "./pages/Auth/AuthMain";
import VerifyPhone from "./pages/Auth/VerifyPhone";
import VerifyPhoneOtp from "./pages/Auth/VerifyPhoneOtp";
import Registration from "./pages/Auth/Registration";
import Intrest from "./pages/Auth/Intrest";
import PostjObMain from "./pages/home/ServicesByINO/PostjOB/PostjObMain";
import PostProjectMain from "./pages/home/ServicesByINO/PostProject/PostProjectMain";
import HomePSliderMain from "./pages/home/HomePSliderPage/HomePSliderMain";
import InquiriesAllSent from "./pages/home/ServicesByINO/FindProduct/Profile/Inquiries/InquiriesAllSent";
import InquiriesAllReceived from "./pages/home/ServicesByINO/FindProduct/Profile/Inquiries/InquiriesAllReceived";
import AllAdwordsMain from "./pages/home/ServicesByINO/FindProduct/Profile/Adwords/AllAdwordsMain";
import AllRequested from "./pages/home/ServicesByINO/FindProduct/Profile/BussinessCard&Contactinformation/AllRequested";
import AllReceived from "./pages/home/ServicesByINO/FindProduct/Profile/BussinessCard&Contactinformation/AllReceived";
import CreateBussinessCard from "./pages/home/ServicesByINO/FindProduct/Profile/BussinessCard&Contactinformation/CreateBussinessCard";
import ViewCard from "./pages/home/ServicesByINO/FindProduct/Profile/BussinessCard&Contactinformation/ViewCard";
import ViewAllInput from "./pages/home/ServicesByINO/FindProduct/Profile/ListoFinput.jsx/ViewAllInput";
import CreateNewCampaign from "./pages/home/ServicesByINO/FindProduct/Profile/ListoFinput.jsx/CreateNewCampaign";
import AllProductHistorymain from "./pages/home/ServicesByINO/FindProduct/Profile/AllProductHistory/AllProductHistorymain";
import CateogrizeProductMain from "./pages/home/ServicesByINO/FindProduct/Profile/CateogrizeProductMarketTrend/CateogrizeProductMain";
import CorprateService from "./pages/home/ServicesByINO/FindProduct/Profile/CopraeService/CorprateService";
import ResellerLayout from "./Layout/ResellerLayout";
import ResellerDashboardMain from "./pages/home/BecomeReseller/REsellerScreens/ResellerDashboardMain";
import UserDetaiLTable from "./pages/home/BecomeReseller/REsellerScreens/UserDetaiLTable";
import ResellerDashboard from "./Layout/ResellerDashboard";
import ResellerForm from "./pages/home/BecomeReseller/ResellerForms/ResellerForm";
import ResellerVerifyPhone from "./pages/home/BecomeReseller/ResellerForms/ResellerVerifyPhone";
// importing function to visible reseller dashboard 
import  {handleButtonClick} from "../src/Layout/Mainlayout"


// // Function definition for handleButtonClick
// export function handleButtonClickd() {
//   // Function logic goes here
// }

const hadlevisible = ()=>{

  ResellerVerifyPhone()
}

// paths
const router = createBrowserRouter(



  createRoutesFromElements(
    <Route>
      <Route path="/" element={<Mainlayout />}>
        <Route index element={<Homestock />} />

        <Route path="findproducts" element={<ProductMainComponent />} />
        {/* profile page routing here  */}
        <Route
          path="/findproducts/inquiriesSent"
          element={<InquiriesAllSent />}
        />
        <Route
          path="/findproducts/inquiriesReceived"
          element={<InquiriesAllReceived />}
        />
        <Route path="/findproducts/adwords" element={<AllAdwordsMain />} />
        <Route path="/findproducts/requestedCard" element={<AllRequested />} />
        <Route
          path="/findproduct/bussinessrequestReceived"
          element={<AllReceived />}
        />
        <Route
          path="/findproduct/bussinessCreatecard"
          element={<CreateBussinessCard />}
        />
        <Route path="/findproduct/businessviewCard" element={<ViewCard />} />
        <Route path="/findproduct/viewallinput" element={<ViewAllInput />} />
        <Route
          path="/findproduct/createnewcampaign"
          element={<CreateNewCampaign />}
        />
        <Route
          path="/findproduct/posthistory"
          element={<AllProductHistorymain />}
        />

        <Route
          path="/findproduct/cateogrizeProduct"
          element={<CateogrizeProductMain />}
        />
        <Route
          path="/findproduct/corporateService"
          element={<CorprateService />}
        />
        {/* profile page routing end here  */}
        <Route />
        <Route path="exploreService" element={<ExploremainComponent />} />
        <Route path="findjobs" element={<JobMain />} />
        <Route path="addProject" element={<AddProjectMain />} />
        <Route path="ListProject" element={<ListMain />} />
        <Route path="PostJob" element={<PostjObMain />} />
        <Route path="PostProject" element={<PostProjectMain />} />
        <Route path="ProductPage" element={<HomePSliderMain />} />
        {/*  */}
        <Route path="chat" element={<Chat />} />
        <Route path="referal" element={<Referalstock />} />
        <Route path="contact-us" element={<Contactstock />} />
        <Route path="TermsandService" element={<Termstock />} />
        <Route path="Privacy&Policy" element={<Privacypolicymain />} />
        <Route path="wallet" element={<Walletstock />} />
        <Route path="/wallet/walletrewards" element={<TransferMain />} />
        <Route path="/wallet/addmoney" element={<Addmoney />} />
        <Route path="License" element={<Licensestock />} />
        <Route path="Helpcenter" element={<Helpmain />} />
        <Route path="/wallet/userwallet" element={<WalletsMoney />} />
        <Route path="/wallet/withdraw" element={<WithdarwWallet />} />
        <Route
          path="/wallet/withdrawlsuccess"
          element={<Withdrawtransfersuccess />}
        />
      </Route>
      {/* authentication or regextration & login pages  */}
      <Route path="auth" element={<AuthLayout />}>
        <Route path="/auth/to" element={<AuthMain />} />
        <Route path="/auth/verification" element={<VerifyPhone />} />
        <Route path="/auth/verifyphone" element={<VerifyPhoneOtp />} />
        <Route path="/auth/registerUser" element={<Registration />} />
        <Route path="/auth/intrest" element={<Intrest />} />
      </Route>

      <Route path="reseller" element={<ResellerDashboard />}>
        <Route path="/reseller/Dashboard" element={<ResellerDashboardMain />} />
        <Route
          path="/reseller/dashboard-userdetail"
          element={<UserDetaiLTable />}
        />
      </Route>
      {/* resleer page routing end  */}

{/* reseller from  */}
<Route path="register" element={<ResellerLayout/>}>

<Route path="/register/resellerForm" element={<ResellerForm/>}/>
<Route path="/register/resellerVerification" element={<ResellerVerifyPhone    />} />

</Route>

    </Route>
  )
);

function Routes() {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default Routes;
