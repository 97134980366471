import React from "react";
import { useState } from "react";
import CatrogrizeAllMain from "./CAteogrizeAll/CatrogrizeAllMain";
import GovMain from "./GovProject/GovMain";
import MandiPrices from "./MandiRate/MandiPrices";
export default function CateogrizeProductMain() {
  const tabsData = [
    {
      label: "ALL",
      content: <CatrogrizeAllMain />,
    },
    {
      label: "Mandi Rate ",
      content: <MandiPrices />,
    },
    {
      label: "GOV Projects",

      content: <GovMain />,
    },
  ];
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  return (
    <>
      <div className="  bg-layout shadow-md drop-shadow-md lg:w-[155vh]  mb-[7rem]  lg:ml-[268px] relative top-[3rem] fade-in-left ">
        <div className=" ml-4 flex space-x-3 border-b justify-center gap-[5rem]">
          {/* Loop through tab data and render button for each. */}
          {tabsData.map((tab, idx) => {
            return (
              <button
                key={idx}
                className={`py-2 border-b-4 transition-colors duration-300 text-[19px] font-all font-semibold ${
                  idx === activeTabIndex
                    ? "border-[#CB2229]  "
                    : "border-transparent hover:border-gray-200"
                }`}
                // Change the active tab on click.
                onClick={() => setActiveTabIndex(idx)}
              >
                {tab.label}
              </button>
            );
          })}
        </div>
        {/* Show active tab content. */}
        <div className=" ">
          <p>{tabsData[activeTabIndex].content}</p>
        </div>
      </div>
    </>
  );
}
