import React from 'react'
import ViewAllPostHistory from './ViewAllPostHistory'
import PendingPost from './PendingPost'

export default function AllProductHistorymain() {
  return (
    <>

<div className="bg-layout shadow-md drop-shadow-md lg:w-[155vh]  mb-[7rem]  lg:ml-[268px] relative top-[3rem] fade-in-left">
    <hr className="w-full mt-4 mb-1 border-gray-500" />
     <ViewAllPostHistory/> 
     <PendingPost/>
     </div>

    </>
  )
}
