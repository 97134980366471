import React from 'react'
import Listuserprofile from './Listuserprofile'

export default function ListProfileMain() {
  return (
    <>


     <Listuserprofile/>

    </>
  )
}
