import React from 'react'
import HomePuserProfile from './HomePuserProfile'

export default function HomePprofilemain() {
  return (
    <>
 <HomePuserProfile/>
    </>
  )
}
