import React from 'react'
import { BsPencil, BsArrowRight } from "react-icons/bs";
import { AiOutlineSend, AiOutlineUserAdd } from "react-icons/ai";
import img from "../../../staticimages/coin.png";
import { MdCallReceived } from "react-icons/md";
export default function Listuserprofile() {
  return (
    <>
                 <div className="--edit - flex flex-col justify-center items-center">
        <div className="--items w-[65%] ">
          {/* a */}
          <div className="--banner-bg mt-8">
            <img
              className="rounded-md h-[10.1rem] w-[100%]"
              src="https://img.freepik.com/free-photo/psychedelic-paper-shapes-with-copy-space_23-2149378246.jpg?w=1380&t=st=1687901795~exp=1687902395~hmac=6a9360fb28a73e1fea5129ffff0a800e96dcbe45f7d35b5a1dde1239cef04fdb"
              alt=""
            />
          </div>
          <div className="flex flex-row">
            <div className="--user-img">
              <img
                class="w-auto h-[112px] 

relative top-[-48px] ml-[22px] rounded-[42%] border-[2px] border-yellow-300"
                src="https://img.freepik.com/free-photo/fun-3d-illustration-american-referee_183364-81231.jpg?w=740&t=st=1687901150~exp=1687901750~hmac=ca3297579d82264444a42318144ed74b1362091dc4cd669b97269df495c255da"
                alt="Medium avatar"
              />
            </div>
            <div className="flex flex-row items-center lg:gap-[28rem]">
              <div className="--user-name flex lg:ml-4 flex-col lg:mt-[6px]">
                <span className="font-all text-[#060606] font-extrabold lg:text-[21px]">
                  Jhon doe
                </span>
                <span className="font-all italic ">identification</span>
              </div>
              <span className="--edit flex flex-row items-center gap-1 cursor-pointer">
                edit <BsPencil />{" "}
              </span>
            </div>
          </div>
          {/* b */}
          <div className="border-[1px] border-[#D2D2D2] rounded-md ">
            <h1 className="intrest font-all txt-[#263238] font-semibold text-[17px] p-[10px] ">
              Interest :
            </h1>
            <div class="coolinput flex flex-col w-fit-content static max-w-240  ml-[28px] mr-[28px] mb-4 ">
              <label
                for="input"
                class=" text text-sm  text-black w-[6rem] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
              >
                add keywords
              </label>
              <input
                type="text"
                placeholder="Write here..."
                name="input"
                class=" input p-[9px] text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
              />
            </div>
          </div>
          {/* c  */}
          <div className="flex flex-row justify-center gap-[1rem] mt-4">
            <div className="border-[1px]  w-[25rem] border-[#D2D2D2] rounded-md mb-1 lg:p-[8px] ">
              <h1 className="intrest font-all txt-[#263238] font-semibold text-[17px] flex flex-row items-center lg:gap-[15rem]">
                Location :{" "}
                <span className="text-[16px]">
                  {" "}
                  <BsPencil />{" "}
                </span>
              </h1>

              <p className="font-all text-[#000000] text-center lg:mt-[13px]">
                Address line 1, city, country, postal code
              </p>
            </div>
            <div className="border-[1px] w-[25rem] border-[#D2D2D2] rounded-md mb-1 lg:p-[8px]">
              <h1 className="intrest font-all txt-[#263238] font-semibold text-[17px] flex flex-row items-center lg:gap-[15rem] ">
                Language :{" "}
                <span className="text-[16px]">
                  {" "}
                  <BsPencil />{" "}
                </span>
              </h1>
              <p className="font-all text-[#000000] text-center lg:mt-[13px]">
                English--(British America)
              </p>
            </div>
          </div>
          {/* d  */}
          <div className="flex flex-row justify-center gap-[1rem] mt-4">
            <div className="border-[1px]  w-[25rem] border-[#D2D2D2] rounded-md mb-1 lg:p-[8px] ">
              <h1 className="intrest font-all txt-[#263238] font-semibold text-[17px] flex flex-row items-center lg:gap-[9rem]">
                Contact information :{" "}
                <span className="text-[16px]">
                  {" "}
                  <BsPencil />{" "}
                </span>
              </h1>

              <p className="font-all text-[#000000] text-center lg:mt-[13px]">
                Address line 1, city, country, postal code
              </p>
            </div>
            <div className="border-[1px] w-[25rem] border-[#D2D2D2] rounded-md mb-1 lg:p-[8px]">
              <h1 className="intrest font-all txt-[#263238] font-semibold text-[17px] flex flex-row items-center lg:gap-[11rem] ">
                Bussiness Profile :{" "}
                <span className="text-[16px]">
                  {" "}
                  <BsPencil />{" "}
                </span>
              </h1>
              <p className="font-all text-[#000000] text-center lg:mt-[13px]">
                English--(British America)
              </p>
            </div>
          </div>
          {/* e  */}
          <div className="border-[1px] border-[#D2D2D2] rounded-md lg:mt-[12px] ">
            <h1 className="intrest font-all txt-[#263238] font-semibold text-[17px] p-[10px] ">
              About Yourself :
            </h1>
            <p className="font-all m-auto mx-[28px]">
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quam
              sequi autem odio recusandae reprehenderit aliquam officia soluta,
              dolor et, accusantium quibusdam? Sit possimus nobis iure
              perspiciatis dolores expedita ea. Reiciendis, ipsam quam deleniti
              quisquam voluptate modi odio ducimus voluptates odit?
            </p>
          </div>

          {/* f  */}
          <div className="lg:flex flex-row gap-4 lg:mt-4">
            <div className="w-[25rem] border-[1px] border-[#D2D2D2] rounded-md p-3">
              <h1 className="intrest font-all txt-[#263238] font-semibold text-[17px] p-[10px] ">
                Inquiries :
              </h1>{" "}
              <div className="flex flex-row lg:mt-[26px] lg:mb-[13px] justify-center gap-4">
                <button className="bg-[#F8F8F8] shadow-lg p-[6px] w-[10rem] rounded-full transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white flex flex-row items-center justify-center gap-2">
                  Sent{" "}
                  <span>
                    <AiOutlineSend />
                  </span>
                </button>
                <button className="bg-[#F8F8F8] shadow-md  p-[6px] w-[10rem] rounded-full transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white flex flex-row items-center justify-center gap-2">
                  Received <MdCallReceived />
                </button>
              </div>
            </div>
            <div className="w-[25rem] border-[1px] border-[#D2D2D2] rounded-md p-3">
              {" "}
              <h1 className="intrest font-all txt-[#263238] font-semibold text-[17px] p-[10px] ">
                Adwords :
              </h1>{" "}
              <div className="flex justify-center items-center">
                <button
                  className="lg:mt-[1.4rem] bg-[#CB2229] shadow-md  p-[7px] w-[19rem] rounded-md transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  
              text-white flex flex-row items-center gap-1 justify-center"
                >
                  Received{" "}
                  <span>
                    <BsArrowRight />
                  </span>
                </button>
              </div>
            </div>
          </div>
          {/* g  */}
          <div className="flex flex-row justify-center gap-4 mt-4">
            <div className="w-[25rem] border-[1px] border-[#D2D2D2] rounded-md ">
              <h1 className="intrest font-all txt-[#263238] font-semibold text-[17px] p-[10px] ">
                Bussiness card & Contact information :
              </h1>{" "}
              <div className="flex flex-row lg:mt-4 lg:mb-[31px] justify-center gap-4">
                <button className="bg-[#F8F8F8] shadow-lg p-[6px] w-[10rem] rounded-full transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white flex flex-row items-center justify-center gap-2">
                  Requested{" "}
                  <span>
                    <AiOutlineUserAdd />
                  </span>
                </button>
                <button className="bg-[#F8F8F8] shadow-md  p-[6px] w-[10rem] rounded-full transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white flex flex-row items-center justify-center gap-2">
                  Received <MdCallReceived />
                </button>
              </div>
              <div className="---2 flex flex-col justify-center items-center gap-2 lg:mb-[25px]">
                <button className=" shadow-lg p-[5px] w-[13rem] rounded-full transform hover:scale-105 duration-500 ease-in-out bg-[#CB2229] text-white ">
                  create bussiness card{" "}
                </button>
                <button className="text-black shadow-lg p-[5px] w-[13rem] rounded-full transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229] border-[#CB2229] border-[1px] hover:text-white ">
                  view card{" "}
                </button>
              </div>
              {/*  */}
            </div>
            <div className="w-[25rem] border-[1px] border-[#D2D2D2] rounded-md ">
              <h1 className="intrest font-all txt-[#263238] font-semibold text-[17px] p-[10px] ">
                List of Input :
              </h1>{" "}
              <div className="flex flex-col items-center justify-center gap-3 lg:mt-[1.7rem]">
                <button className=" shadow-lg lg:p-[6px] w-[20rem] rounded-md transform hover:scale-105 duration-500 ease-in-out bg-[#CB2229] border-[#CB2229] border-[1px] text-white flex flex-row items-center gap-3 justify-center lg:mt-4">
                  view all input{" "}
                  <span>
                    <BsArrowRight />
                  </span>
                </button>
                {/*  */}

                <button className="text-black shadow-lg p-[6px] w-[20rem] rounded-md transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229] border-[#CB2229] border-[1px] hover:text-white ">
                  create new campaign
                </button>

                {/*  */}
              </div>
            </div>
          </div>
          {/* h  */}
          <div className="border-[1px] border-[#D2D2D2] rounded-md mt-4 ">
            <div className="---a  flex fex-row justify-start ml-8 lg:gap-[27rem]">
              <h1 className="intrest font-all text-[#263238] font-semibold text-[17px] p-[10px] ">
                Post History :
              </h1>{" "}
              <h1 className="intrest font-all text-[#263238]   p-[10px]  underline underline-offset-8 ">
                view full activity :
              </h1>{" "}
            </div>

            <div className="--product-decs">
              <div className="---product flex flex-row gap-3 items-center border-[1px] lg:ml-[65px] lg:mr-[46px] mt-3 mb-3 rounded-md">
                <div className="img">
                  <img
                    className="w-[308px]"
                    src="https://www.nike.sa/dw/image/v2/BDVB_PRD/on/demandware.static/-/Sites-akeneo-master-catalog/default/dw3febf6df/nk/e20/f/7/d/6/b/e20f7d6b_77f8_4a99_99e2_b2a2cb69de46.png?sw=520&sh=520&sm=fit"
                    alt=""
                  />
                </div>
                <div className="--product--desc font-all p-[4px] ">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus
                  ab earum, id eius eum corrupti mollitia ratione, quae, ad et
                  officia!
                </div>
              </div>
              <div className="---product flex flex-row gap-3 items-center border-[1px] lg:ml-[65px] lg:mr-[46px] mb-4 rounded-md">
                <div className="img">
                  <img
                    className="w-[308px]"
                    src="https://www.nike.sa/dw/image/v2/BDVB_PRD/on/demandware.static/-/Sites-akeneo-master-catalog/default/dwea021436/nk/dad/7/3/e/3/e/dad73e3e_0759_4c4d_8d75_7a50d87e5459.png?sw=520&sh=520&sm=fit"
                    alt=""
                  />
                </div>
                <div className="--product--desc font-all p-[4px] ">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus
                  ab earum, id eius eum corrupti mollitia ratione, quae, ad et
                  officia!
                </div>
              </div>
            </div>
          </div>
          {/* i  */}

          <div className="flex flex-row gap-4 mt-4 mb-4">
            <div className="--1 border-[1px] w-[34rem] border-[#D2D2D2] rounded-md ">
              <div className="text--desc flex fex-row justify-start lg:gap-[17rem]">
                <h1 className="intrest font-all text-[#263238] font-semibold text-[17px] p-[10px] ">
                  Wallet :
                </h1>
                <h1 className="intrest font-all text-[#263238]   p-[10px]  underline underline-offset-8 border-[#D2D2D2] ">
                  view activity
                </h1>{" "}
              </div>

              {/* <hr className="border-[1px] border-dashed border-[#D2D2D2]  " /> */}

              <div
                className="--wallet-desc flex flex-row items-center justify-center gap-16 mt-3
 "
              >
                <div className="--wallet-desc-&-balance flex flex-col ">
                  <h6 className="font-all font-semibold text-[18px]">
                    Current Balance
                  </h6>
                  <span>$XX.XX.X</span>
                </div>
                <img
                  src={img}
                  alt=""
                  className="--wallet-img h-[10rem] img-coin image-coin"
                />
              </div>
            </div>
            {/* j  */}

            <div className="border-[1px] rounded-md p-[12px]">
              <div className="flex flex-col gap-3 mt-6">
                <button className="text-black shadow-lg p-[6px] w-[17rem] rounded-md transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229] border-[#CB2229] border-[1px] hover:text-white ">
                  Cateogrize product market trends
                </button>

                <button className=" shadow-lg p-[6px] w-[17rem] rounded-md transform hover:scale-105 duration-500 ease-in-out bg-[#CB2229] border-[#CB2229] border-[1px] text-white flex flex-row gap-3 items-center justify-center">
                  Corporate Services <BsArrowRight />
                </button>
              </div>
            </div>
          </div>
          {/* ed */}
        </div>
      </div> 
    </>
  )
}
