import React from 'react'
import { Outlet } from 'react-router-dom'
import Sidebar from '../component/Sidebar'

export default function ResellerDashboard() {
  return (
    <>
     <Sidebar/>
     <Outlet/> 
    </>
  )
}
