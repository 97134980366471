import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import {
  AiOutlineHome,
  AiOutlineMail,
  AiFillLock,
  AiOutlineQuestionCircle,
} from "react-icons/ai";

import { IoChatbubblesSharp, IoWalletOutline } from "react-icons/io5";
import { HiOutlineLink } from "react-icons/hi";
import { TbLogout } from "react-icons/tb";
import { VscKey } from "react-icons/vsc";
import Logo from "../assets/logo/logo.jpg";
import { IoNewspaperOutline } from "react-icons/io5";
import {BsGraphUpArrow} from "react-icons/bs"
function Sidebar({ toggleModal }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleSidebarToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const [activeIndex, setActiveIndex] = useState(0);
  const handleClick = (index) => {
    setActiveIndex(index);
  };
  const handleClickd = () => {
    toggleModal();
    // Additional logic or actions
  };
  return (
    <>
      <div>
        <button
          data-drawer-target="default-sidebar"
          data-drawer-toggle="default-sidebar"
          aria-controls="default-sidebar"
          type="button"
          className="inline-flex items-center p-2 mt-2 ml-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          onClick={handleSidebarToggle}
        >
          <span className="sr-only">Open sidebar</span>
          <svg
            className="w-6 h-6"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              clipRule="evenodd"
              fillRule="evenodd"
              d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
            ></path>
          </svg>
        </button>

        <aside
          id="default-sidebar"
          className={`fixed  lg:w-[29vh]  top-0 left-0 z-40 w-64 h-screen transition-transform ${
            isSidebarOpen ? "translate-x-0" : "-translate-x-full"
          } sm:translate-x-0`}
          aria-label="Sidebar"
        >
          <aside
            id="default-sidebar"
            class="fixed top-0 left-0 z-40 w-64  h-screen lg:w-[29vh] transition-transform -translate-x-full sm:translate-x-0 "
            aria-label="Sidebar"
          >
            <div class="h-full px-3 py-4 overflow-y-auto  bg-white shadow-md drop-shadow dark:bg-gray-800">
              <div className="logo flex items-center">
                <img src={Logo} className="lg:h-[45px] lg:mx-auto" alt="" />
              </div>

              <div className="user mt-4 mb-4 bg-[#E98F92] flex flex-row items-center justify-center gap-3 h-[3rem] rounded-md">
                <img
                  class="w-10 h-10 rounded-full"
                  src="https://img.freepik.com/free-vector/handsome-man_1308-85984.jpg?w=740&t=st=1685537521~exp=1685538121~hmac=8c6f33c4bea1274cb158f61f48d9b040e06af55bc541374cbf47d5aeb024d7b5"
                  alt="Rounded avatar"
                />
                <p className="font-all uppercase text-[#263238] font-semibold">
                  Jhon doe
                </p>
              </div>
              <ul class="space-y-2 font-medium">
                <NavLink to="/">
                  {" "}
                  <li
                    className={`${activeIndex === 0 ? "bg-nav" : ""}`}
                    onClick={() => handleClick(0)}
                  >
                    <span class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white  hover:bg-[#f7dedf] cursor-pointer ">
                      <AiOutlineHome className="text-[17.5px]" />
                      <span class="ml-3 font-all">Home</span>
                    </span>
                  </li>
                </NavLink>



                <NavLink to="/reseller/Dashboard">
                  {" "}
                  <li
                    className={`${activeIndex === 1 ? "bg-nav" : ""}`}
                    onClick={() => handleClick(1)}
                  >
                    <span class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white  hover:bg-[#f7dedf] cursor-pointer ">
                      <BsGraphUpArrow className="text-[17.5px]" />
                      <span class="ml-3 font-all">Dashboard</span>
                    </span>
                  </li>
                </NavLink>








                <NavLink to="chat">
                  {" "}
                  <li
                    className={`${activeIndex === 2 ? "bg-nav" : ""}`}
                    onClick={() => handleClick(2)}
                  >
                    <span class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white  hover:bg-[#f7dedf] cursor-pointer ">
                      <IoChatbubblesSharp className="text-[17.5px]" />
                      <span class="flex-1 ml-3 font-all whitespace-nowrap">
                        Chats
                      </span>
                    </span>
                  </li>
                </NavLink>

<NavLink to="wallet">  <li
                  className={`${activeIndex === 3 ? "bg-nav" : ""}`}
                  onClick={() => handleClick(3)}
                >
                  <span class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white  hover:bg-[#f7dedf] cursor-pointer ">
                    <IoWalletOutline className="text-[17.5px]" />
                    <span class="flex-1 ml-3 font-all whitespace-nowrap">
                      Wallet
                    </span>
                  </span>
                </li></NavLink>

               
          
          <NavLink to="referal" >  <li
                  className={`${activeIndex === 4 ? "bg-nav" : ""}`}
                  onClick={() => handleClick(4)}
                >
                  <span class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white  hover:bg-[#f7dedf] cursor-pointer ">
                    <HiOutlineLink className="text-[17.5px]" />
                    <span class="flex-1 ml-3 font-all whitespace-nowrap">
                      Referal
                    </span>
                  </span>
                </li></NavLink>
              
             <NavLink to="contact-us"><li
                  className={`${activeIndex === 5 ? "bg-nav" : ""}`}
                  onClick={() => handleClick(5)}
                >
                  <span class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white  hover:bg-[#f7dedf] cursor-pointer ">
                    <AiOutlineMail className="text-[17.5px]" />
                    <span class="flex-1 ml-3 font-all whitespace-nowrap">
                      Contact us
                    </span>
                  </span>
                </li></NavLink>
                 
                <NavLink to="TermsandService">
                  {" "}
                  <li
                    className={`${activeIndex === 6 ? "bg-nav" : ""}`}
                    onClick={() => handleClick(6)}
                  >
                    <span class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white  hover:bg-[#f7dedf] cursor-pointer ">
                      <IoNewspaperOutline />
                      <span class="flex-1 ml-3 font-all whitespace-nowrap">
                        Terms of Service
                      </span>
                    </span>
                  </li>
                </NavLink>

                <NavLink to="Privacy&Policy">
                  <li
                    className={`${activeIndex === 7 ? "bg-nav" : ""}`}
                    onClick={() => handleClick(7)}
                  >
                    <span class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white  hover:bg-[#f7dedf] cursor-pointer ">
                      <AiFillLock className="text-[17.5px]" />
                      <span class="flex-1 ml-3 font-all whitespace-nowrap">
                        Privacy Policy
                      </span>
                    </span>
                  </li>
                </NavLink>

                <NavLink to="License">
                  <li
                    className={`${activeIndex === 8 ? "bg-nav" : ""}`}
                    onClick={() => handleClick(8)}
                  >
                    <span class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white  hover:bg-[#f7dedf] cursor-pointer ">
                      <VscKey className="text-[17.5px]" />
                      <span class="flex-1 ml-3 font-all whitespace-nowrap">
                        Licenses
                      </span>
                    </span>
                  </li>
                </NavLink>

                <NavLink to="Helpcenter">
                  <li
                    className={`${activeIndex === 9 ? "bg-nav" : ""}`}
                    onClick={() => handleClick(9)}
                  >
                    <span class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white  hover:bg-[#f7dedf] cursor-pointer ">
                      <AiOutlineQuestionCircle className="text-[17.5px]" />
                      <span class="flex-1 ml-3 font-all whitespace-nowrap">
                        Help Center
                      </span>
                    </span>
                  </li>
                </NavLink>

                <li
                  className={`${activeIndex === 10 ? "bg-nav" : ""}`}
                  onClick={() => handleClick(10)}
                >
                  <span
                    onClick={handleClickd}
                    class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white  hover:bg-[#f7dedf] cursor-pointer "
                  >
                    <TbLogout className="text-[17.5px]" />
                    <span class="flex-1 ml-3 font-all whitespace-nowrap">
                      Logout
                    </span>
                  </span>
                </li>
              </ul>
            </div>
          </aside>
        </aside>
      </div>
    </>
  );
}

export default Sidebar;
