import React, { useState, useEffect } from "react";
import PallMain from "./HomePAll/PallMain";
import PinputMain from "./HomePinput/PinputMain";
import HomePpostMain from "./HomePpost/HomePpostMain";
import HomePprofilemain from "./HomePprofile/HomePprofilemain";
import Productpage from "./ProductPage/Productpage";

export default function HomePSliderMain() {
  const [showHomePost, setShowHomePost] = useState(false);
  const handleClick = () => {
    setShowHomePost(!showHomePost);
  };
  const handlereverse = () => {
    setShowHomePost(showHomePost);
  };

  const tabsData = [
    {
      label: "ALL",
      content: showHomePost ? (
        <Productpage onClick={handlereverse} />
      ) : (
        <PallMain onClick={handleClick} />
      ),
    },
    {
      label: "INPUT",
      content: <PinputMain />,
    },
    {
      label: "POST",
      content: <HomePpostMain />,
    },
    {
      label: "PROFILE",
      content: <HomePprofilemain />,
    },
  ];

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="bg-layout shadow-md drop-shadow-md lg:w-[155vh] mb-[7rem] lg:ml-[268px] relative top-[3rem]">
        <div className="ml-4 flex space-x-3 border-b justify-center gap-[5rem]">
          {/* Loop through tab data and render button for each. */}
          {tabsData.map((tab, idx) => {
            return (
              <button
                key={idx}
                className={`py-2 border-b-4 transition-colors duration-300 text-[19px] font-all font-semibold ${
                  idx === activeTabIndex
                    ? "border-[#CB2229]  "
                    : "border-transparent hover:border-gray-200"
                }`}
                // Change the active tab on click.
                onClick={() => setActiveTabIndex(idx)}
              >
                {tab.label}
              </button>
            );
          })}
        </div>
        {/* Show active tab content. */}
        <div className="">{tabsData[activeTabIndex].content}</div>
      </div>
    </>
  );
}
